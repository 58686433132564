<script setup>
import {
  TellerIcon,
  HistoryIcon,
  LogoutIcon,
  GearIcon,
  StatusIcon,
} from '~/components/design-system/icons';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import Button from '~/components/design-system/button.vue';
import List from '~/components/design-system/list/index.vue';
import ListItem from '~/components/design-system/list/list-item.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useAccountOptions } from '~/composables/useAccountOptions';
import Select from 'primevue/select';
const { $t } = useNuxtApp();
const auth = useAuthStore();
const breakpoint = useBreakpoint();
const placeholder = ref('');
const sideBar = [
  { id: 'teller', key: 'teller', route: '?account=teller', icon: TellerIcon },
  {
    id: 'history',
    key: 'history',
    route: '?account=history',
    icon: HistoryIcon,
  },
  {
    id: 'settings',
    key: 'account-settings',
    route: '?account=settings',
    icon: GearIcon,
  },
  { id: 'logout', key: 'logout', route: '?account=logout', icon: LogoutIcon },
];
function navigateMobile(val) {
  auth.setAccountModalPage(val.value);
}

const state = reactive({
  renderSelector: breakpoint.mdOrLess,
});

const activeRoute = (routeOption) => auth.getAccountModalPage === routeOption;

onMounted(() => {
  //on load get correct dropdown category
  const active = sideBar.find((el) => {
    return activeRoute(el.route);
  });
  placeholder.value = active?.key ?? 'teller'; //if null default to teller
});

function toggleModal() {
  auth.toggleAccountModal(false);
  auth.setAccountModalPage(null);
}

const exclusionKeys = [
  'password-management-success',
  'transaction-summary-detail',
];

const filteredAccountOptions = computed(() =>
  useAccountOptions()
    .filter((uao) => !exclusionKeys.includes(uao.key) && uao.active)
    .map((uao) => {
      return { ...uao, key: $t(uao.key) };
    }),
);
</script>
<template>
  <div v-if="!state.renderSelector" class="p-3 sticky top-0 px-2">
    <List>
      <ListItem v-for="{ id, key } in filteredAccountOptions" :key="id">
        <Button
          class="flex justify-content-start items-center w-full mb-2 text-sm capitalize"
          :type="activeRoute(id) ? 'primary' : 'secondary'"
          size="medium"
          @click="auth.setAccountModalPage(id)"
        >
          <div class="truncate">
            {{ $t(key) }}
          </div>
          <div class="status-icon">
            <StatusIcon
              v-if="!auth.getDocumentVerificationStatus && key === 'settings'"
              :status="'incomplete'"
            />
          </div>
        </Button>
      </ListItem>
    </List>
  </div>
  <div v-if="state.renderSelector">
    <div class="flex items-center mb-2 gap-2 px-2">
      <Select
        :options="filteredAccountOptions"
        option-label="key"
        option-value="id"
        :placeholder="$t(placeholder)"
        append-to="self"
        :model-value="auth.getAccountModalPage"
        class="w-full input-style !bg-light-200 dark:!bg-dark-900 border-0 py-1 capitalize"
        @change="navigateMobile($event)"
      />
      <Button type="tertiary" @click="toggleModal()">
        <XMarkIcon />
      </Button>
    </div>
    <div class="bg-light-200 dark:bg-dark-900 w-full h-[1px]" />
  </div>
</template>

<style scoped>
.status-icon {
  position: absolute;
  z-index: 1000;
  right: 5px;
}
</style>
